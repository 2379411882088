import { useMemo } from 'react';
import { DocumentWidgetVariant } from './Documents';
import { useAnalyticsState } from 'src/state/machines/analytics/analyticsProvider';
import { useAccessedDocuments, useAllDocuments } from 'src/state/redux/selector/document';
import docQuery from 'src/state/redux/document/query';
import { useCurrentUserORM } from 'src/state/redux/selector/user';

export const getAvailableVariant = (currentVariant: DocumentWidgetVariant): DocumentWidgetVariant => {
  return {
    [DocumentWidgetVariant.MOST_VIEWED]: DocumentWidgetVariant.TEAM_MOST_VIEWED,
    [DocumentWidgetVariant.TEAM_MOST_VIEWED]: DocumentWidgetVariant.MOST_VIEWED,
    [DocumentWidgetVariant.RECENTLY_UPDATED]: DocumentWidgetVariant.RECENTLY_VIEWED,
    [DocumentWidgetVariant.RECENTLY_VIEWED]: DocumentWidgetVariant.RECENTLY_UPDATED,
  }[currentVariant];
};

export const useGetWidgetsDocuments = (variant: DocumentWidgetVariant) => {
  const {
    personal: {
      recentlyAccessedDocuments: recentlyAccessedDocumentEvents,
      mostAccessedDocuments: mostAccessedDocumentsCount,
    },
    tenant: {
      mostAccessedDocuments: tenantMostAccessedDocumentsCount,
    },
  } = useAnalyticsState();
  const userORM = useCurrentUserORM();

  const documentIds = useMemo(() =>
    Array.from(new Set([
      ...mostAccessedDocumentsCount.map((record) => record.id),
      ...tenantMostAccessedDocumentsCount.map((record) => record.id),
      ...recentlyAccessedDocumentEvents.map((event) => event.entityId),
    ]))
  , [mostAccessedDocumentsCount, recentlyAccessedDocumentEvents, tenantMostAccessedDocumentsCount]);

  const queryOpts = useMemo(() =>
    docQuery.merge(
      docQuery.filters.published,
      { filter: { model: { id: documentIds } } }), [documentIds]);

  const recentlyUpdatedQuery = docQuery.merge(
    docQuery.filters.published,
    docQuery.filters.presentable,
    docQuery.sorts.updatedAtDesc,
    docQuery.filters.defaultFilters(userORM?.meta.customFilterValues.defaultFilters || {}),
  )

  const recentlyUpdatedDocuments = useAllDocuments(recentlyUpdatedQuery);
  const recentlyAccessedDocuments =
    useAccessedDocuments(queryOpts, true, recentlyAccessedDocumentEvents, mostAccessedDocumentsCount);
  const personalMostAccessedDocuments =
    useAccessedDocuments(queryOpts, false, recentlyAccessedDocumentEvents, mostAccessedDocumentsCount);
  const teamMostAccessedDocuments =
    useAccessedDocuments(queryOpts, false, recentlyAccessedDocumentEvents, tenantMostAccessedDocumentsCount);

  const requiredDocuments = {
    [DocumentWidgetVariant.RECENTLY_UPDATED]: recentlyUpdatedDocuments,
    [DocumentWidgetVariant.MOST_VIEWED]: personalMostAccessedDocuments,
    [DocumentWidgetVariant.TEAM_MOST_VIEWED]: teamMostAccessedDocuments,
    [DocumentWidgetVariant.RECENTLY_VIEWED]: recentlyAccessedDocuments,
  };

  return requiredDocuments[variant];
};
