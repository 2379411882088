import type {
  StateFrom,
  InterpreterFrom,
  Prop,
  EventObject,
  ActorRef,
} from 'xstate';
import { EntityViewedEvent, MostAccessedRecordsOutput, UserSettings } from '@alucio/aws-beacon-amplify/src/models';
import type analyticsSM from './analytics.machine';
import type { Typegen0 } from './analytics.machine.typegen';
import { User } from '../logger/logger.types';
export type { User };

export type AnalyticsState = StateFrom<typeof analyticsSM>
export type AnalyticsService = InterpreterFrom<typeof analyticsSM>
export type AnalyticsSend = Prop<AnalyticsService, 'send'>
export type AnalyticsMachine = typeof analyticsSM

export enum ANALYTIC_CATEGORY_ENUM {
  DOCUMENT = 'DOCUMENT',
  FOLDER = 'FOLDER'
}

export type ANALYTIC_CATEGORY = keyof typeof ANALYTIC_CATEGORY_ENUM;

export const ACCESSED_DOCUMENT_EVENTS = ['DOCUMENT_PRESENT', 'DOCUMENT_CONTENT_PREVIEW'];
export const ACCESSED_FOLDER_EVENTS = ['FOLDER_ACCESSED'];

export type IndexedDBDataFormat = {
  ket: string
  payload: LocalAccessedRecords
}

export type LocalAccessedRecords = {
  userId: string
  category: ANALYTIC_CATEGORY
  entityId: string
  timestamp: string
}

export type RecordViewsCount = {
  id: string,
  count: number,
}

export type IndexedRecords = {
  [id: string]: number
}

export type AnalyticsData = {
  personal: {
    mostAccessedDocuments: RecordViewsCount[],
    recentlyAccessedDocuments: EntityViewedEvent[],
    recentlyAccessedFolders: EntityViewedEvent[],
  }
  tenant: {
    mostAccessedDocuments: RecordViewsCount[],
  },
}

export enum StateTags {
  MACHINE_READY = 'MACHINE_READY',
}

export type SMContext = {
  currentUserId?: string
  // ARRAY OF INDIVIDUAL ACTIONS TO BE MIXED UP WITH LAMBDA RESULTS //
  localAnalytics: LocalAccessedRecords[]
  // ARRAY OF ACTIONS TO BE STORED ON IDB //
  analyticsToBatchStore: LocalAccessedRecords[]
  // USER'S SETTINGS TO BE UPDATED/USED TO DISPLAY ACCESSED RECORDS //
  userSettings?: UserSettings
  // RESPONSE OF MOST ACCESSED RECORDS FROM LAMBDA //
  mostAccessedRecordsFromLambda?: MostAccessedRecordsOutput
  // FINAL STRUCTURED ANALYTICS DATA TO BE ACCESSED TO DISPLAY (MIX OF LAMBDA RESPONSE AND LOCAL ANALYTICS) //
  analyticsData: AnalyticsData
  nextAthenaRecordRetrievalTime?: number
  authActor?: ActorRef<any, EVT_USER_SIGNED_IN | EVT_USER_SIGNED_IN>
  userSettingsObservableActor?: ActorRef<any, EVT_UPDATE_CONTEXT_USER_SETTINGS>
  analyticsObservableActor?: ActorRef<any, EVT_ANALYTICS_ACTION_TRIGGERED>
  err?: any
}

export type SMServices = {
  getUserData: {
    data: User
  },
  storeUserAnalyticsToIndexedDB: {
    data: void
  },
  getUserAnalyticsFromIndexedDB: {
    data: LocalAccessedRecords[]
  },
  clearAnalyticsIndexedDB: {
    data: void
  },
  retrieveAthenaRecord: {
    data: MostAccessedRecordsOutput
  },
}

export type SMContextKeys = keyof SMContext
export type StringStates = Extract<Typegen0['matchesStates'], string>

export type EvtValues<
  K extends T['type'],
  T extends EventObject = SMEvents
> = T extends { type: K }
  ? Omit<T, 'type'>
  : never

export type EVT_USER_SIGNED_IN = {
  type: 'USER_SIGNED_IN',
}

export type EVT_USER_SIGNED_OUT = {
  type: 'USER_SIGNED_OUT',
}

export type EVT_USER_SIGNING_OUT = {
  type: 'USER_SIGNING_OUT',
}

export type EVT_UPDATE_CONTEXT_USER_SETTINGS = {
  type: 'UPDATE_CONTEXT_USER_SETTINGS'
  payload: UserSettings
}

export type EVT_ANALYTICS_ACTION_TRIGGERED = {
   type: 'ANALYTICS_ACTION_TRIGGERED'
   payload: any[]
}

export type EVT_RETRIEVE_ATHENA_RECORD = {
  type: 'RETRIEVE_ATHENA_RECORD'
}

export type SMEvents =
  | EVT_USER_SIGNED_IN
  | EVT_USER_SIGNED_OUT
  | EVT_USER_SIGNING_OUT
  | EVT_UPDATE_CONTEXT_USER_SETTINGS
  | EVT_ANALYTICS_ACTION_TRIGGERED
  | EVT_RETRIEVE_ATHENA_RECORD
