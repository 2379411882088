import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Iffy, DNABox, DNAButton, DNAIcon, DNAText } from '@alucio/lux-ui';
import { WebLinking as Linking } from '@alucio/core';
import workerChannel from 'src/worker/channels/workerChannel';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useSyncState } from 'src/state/redux/selector/cache';
import * as logger from 'src/utils/logger';

const styles = StyleSheet.create({
  offlineActivatedText: {
    marginLeft: 5,
  },
});

export enum ToggleOfflineButtonVariant {
  Activate = 'Activate',
  Deactivate = 'Deactivate',
}

const ToggleOfflineButton: React.FC<{ variant: ToggleOfflineButtonVariant }> = ({ variant }) => {
  const { isOfflineEnabled } = useAppSettings();
  const syncState = useSyncState();
  const [isWaitingToDisable, setIsWaitingToDisable] = useState<boolean>(false);

  const disableServiceWorker = async () => {
    // We subscribe to watch for a purge success message from the SW before terminating
    // the service worker
    workerChannel
      .observable
      .filter(msg => msg.type === 'CACHE_DB' && msg.value === 'PURGE_COMPLETE')
      .subscribe(async () => {
        logger.userInit.debug('DB Purge Successful.')
        const registration = await navigator.serviceWorker.getRegistration('/')
        registration?.unregister().then((isUnregistered) => {
          logger.userInit.info('SW Unregistered', isUnregistered)
          Linking.openURL('/profile/offline', '_self');
        })
      })

    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }

  useEffect(() => {
    if (isWaitingToDisable && syncState.matches('online.paused')) {
      disableServiceWorker()
    }
  }, [isWaitingToDisable, syncState])

  const toggleOffline = async () => {
    if (isOfflineEnabled) {
      if (syncState.matches('online.sync')) {
        workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
        setIsWaitingToDisable(true)
      } else {
        disableServiceWorker();
      }
      analytics.track('SYNC_UNINSTALL', {
        action: 'UNINSTALL',
        category: 'SYNC',
      });
    } else {
      // Register the service worker and reload
      navigator.serviceWorker
        .register('/worker.js')
        .then(registration => {
          logger.userInit.info('SW registered: ', registration);
          Linking.openURL('/profile/offline', '_self');
        })
        .catch(registrationError => {
          logger.userInit.error('SW registration failed: ', registrationError);
        });

      analytics.track('SYNC_INSTALL', {
        action: 'install',
        category: 'sync',
      });
    }
  }

  return (
    <>
      <Iffy is={variant === 'Activate' && isOfflineEnabled}>
        <DNABox>
          <DNAIcon.Styled name="check" appearance="ghost" status="success"/>
          <DNAText
            status="success"
            style={styles.offlineActivatedText}
            testID="offline-activated-text"
            children="Activated"
          />
        </DNABox>
      </Iffy>
      <Iffy is={variant === 'Activate' && !isOfflineEnabled}>
        <DNAButton
          padding="sm"
          onPress={toggleOffline}
          testID="offline-activate-button"
          children="Activate"
        />
      </Iffy>
      <Iffy is={variant === 'Deactivate'}>
        <DNAButton
          padding="sm"
          onPress={toggleOffline}
          testID="offline-deactivate-button"
          children="Deactivate"
        />
      </Iffy>
    </>
  )
}

export default ToggleOfflineButton;
