import React from 'react';
import { StyleSheet } from 'react-native';
import { DocumentORM } from 'src/types/types';
import { DNARowsComponent } from 'src/components/DNA/FlatList/Rows/types';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import { DNABox, DNACard, DNAText, DNAButton, Iffy, DNAChip } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import DNADocumentThumbnail from 'src/components/DNA/Document/DNADocumentThumbnail';
import DNADocumentContextMenu from 'src/components/DNA/Document/DNADocumentContextMenu/DNADocumentContextMenu';
import { DNADocumentDefaultRowProps } from '../default/DNADocumentDefaultRow';

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
  },
  unavailableContentBadge: {
    backgroundColor: colors['color-black'],
  },
  unavailableContainer: {
    opacity: 0.4,
    paddingVertical: 12,
  },
});

const DNADocumentDashboardRow: DNARowsComponent<DocumentORM, DNADocumentDefaultRowProps> = (props) => {
  const { ORM: documentORM, isOnline, onPin } = props
  const isBookmarked = documentORM.meta.bookmark.isBookmarked;
  const documentActions = useDNADocumentActions();
  const documentVersionORM = documentORM.relations.version.latestPublishedDocumentVersionORM ||
    documentORM.relations.version.latestDocumentVersionORM;
  const isContentAvailable = isOnline ||
    (documentVersionORM?.meta.assets.isContentCached && documentVersionORM.model.type !== FileType.WEB);

  const onBookmark = (): void => {
    documentActions.bookmark(documentORM)()
    onPin?.()
  };

  const onPress = (): void => {
    isContentAvailable && props.onPress?.(documentORM);
  };

  return (
    <DNACard
      appearance="flat"
      interactive="pressable"
      onPress={onPress}
      style={styles.container}
    >
      <DNABox
        style={!isContentAvailable && styles.unavailableContainer}
        testID="dashboard-document-row"
        alignY="center"
        spacing="between"
        childFill={0}
      >
        <DNABox alignY="center" spacing="md" childFill={1}>
          <DNABox appearance="row">
            {/* BOOKMARK */}
            <DNAButton
              status="gray"
              appearance="ghost"
              onPress={onBookmark}
              iconLeft={isBookmarked ? 'bookmark' : 'bookmark-outline'}
              size="md"
              padding="sm"
            />
            {/* THUMBNAIL */}
            <DNADocumentThumbnail
              documentVersionORM={documentVersionORM}
              width={76}
              height={44}
              unavailableContent={!isContentAvailable}
              showProcessing={isContentAvailable}
            />
          </DNABox>
          <DNABox appearance="col" spacing="sm">
            {/* TITLE */}
            <DNAText testID={`document-row-name-${documentVersionORM.model.title}`} b1 numberOfLines={1}>
              {documentVersionORM.model.title}
            </DNAText>
            {/* UNAVAILABLE CONTENT BADGE */}
            <Iffy is={!isContentAvailable}>
              <DNABox>
                <DNAChip
                  appearance="tag"
                  style={styles.unavailableContentBadge}
                  testID={`content-not-available-${documentORM.model.id}`}
                >
                  CONTENT NOT AVAILABLE
                </DNAChip>
              </DNABox>
            </Iffy>
          </DNABox>
        </DNABox>
        <DNABox spacing="sm" alignY="center">
          {/* ELLIPSIS */}
          <DNADocumentContextMenu documentORM={documentORM}>
            <DNAButton
              status="gray"
              appearance="ghost"
              iconLeft="dots-vertical"
              size="md"
              padding="sm"
            />
          </DNADocumentContextMenu>
        </DNABox>
      </DNABox>
    </DNACard>
  )
}

export default DNADocumentDashboardRow
