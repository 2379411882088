import React from 'react';
import { StyleSheet } from 'react-native';
import { Iffy, DNABox, DNAIcon, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

const styles = StyleSheet.create({
  installStep: {
    borderColor: colors['color-gray-80'],
    borderBottomWidth: 1,
    padding: 20,
  },
  installStepLastItem: {
    padding: 20,
  },
  installStepNumber: {
    marginRight: 20,
  },
});

interface BeaconAppInstallStepRowProps {
  stepNum: number,
  text: string,
  rightElement?: React.ReactElement,
  isLastItem?: boolean,
}

const BeaconAppInstallStepRow: React.FC<BeaconAppInstallStepRowProps> = (props) => {
  const { stepNum, text, rightElement, isLastItem } = props
  return (
    <DNABox style={isLastItem ? styles.installStepLastItem : styles.installStep} alignY="center">
      <DNABox fill>
        <DNAIcon.Styled
          appearance="outline"
          name={`numeric-${stepNum}-box`}
          size="lg"
          status="primary"
          style={styles.installStepNumber}
        />
        <DNAText>{text}</DNAText>
      </DNABox>
      <Iffy is={rightElement}>
        <DNABox style={{ marginLeft: 20 }} alignX="end">{rightElement}</DNABox>
      </Iffy>
    </DNABox>
  )
}

export default BeaconAppInstallStepRow;
