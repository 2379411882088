import { useEffect } from 'react';
import useLazyRef from 'src/hooks/useLazyRef';
import debounce from 'lodash/debounce'
import { observeSlice } from 'src/state/redux';
import { SyncStateORM, syncState } from 'src/state/redux/selector/cache'
import { matchesState } from 'xstate';

const track = debounce(
  (event, data) => {
    analytics?.track(event, data)
  },
  100,
)

let isResumingSyncing = false;

const contentSyncAnalyticsHandler = (val: { prev: SyncStateORM, next: SyncStateORM }) => {
  const { prev, next } = val;
  const totalNumberOfDocumentVersions = next.totalNumberOfDocumentVersions;

  const prevState = {
    isSyncing: matchesState('online.sync.thumbnails', prev.value) ||
               matchesState('online.sync.pagesJson', prev.value) ||
               matchesState('online.sync.content', prev.value),
    isComplete: matchesState('online.idle', prev.value),
    isPaused: matchesState('online.paused', prev.value),
  };

  const nextState = {
    isSyncing: matchesState('online.sync.thumbnails', next.value) ||
               matchesState('online.sync.pagesJson', next.value) ||
               matchesState('online.sync.content', next.value),
    isComplete: matchesState('online.idle', next.value),
    isPaused: matchesState('online.paused', next.value),
    isInSyncState: matchesState('online.sync', next.value),
  };

  if (!prevState.isPaused && nextState.isPaused) {
    isResumingSyncing = false;
    track('SYNC_PAUSE', {
      action: 'PAUSE',
      category: 'SYNC',
    });
  }

  if (!prevState.isSyncing && nextState.isSyncing && !isResumingSyncing) {
    track('SYNC_START', {
      action: 'START',
      category: 'SYNC',
    });
  }

  if (prevState.isPaused && !nextState.isPaused && nextState.isInSyncState) {
    isResumingSyncing = true;
    track('SYNC_RESUME', {
      action: 'RESUME',
      category: 'SYNC',
    });
  }

  if (prevState.isSyncing && nextState.isComplete) {
    const errorEntries = next.context?.error?.syncEntries || {};
    const totalErrors = new Set(Object.keys(errorEntries).map((entryId) =>
      errorEntries[entryId].documentVersionId)).size;
    const totalSynced = totalNumberOfDocumentVersions - totalErrors;

    track('SYNC_COMPLETE', {
      action: 'COMPLETE',
      category: 'SYNC',
      totalErrors,
      totalSynced,
    });
  }
}

const contentSyncObserver = () => {
  let prevState: SyncStateORM
  let tempState: SyncStateORM

  const obs = observeSlice<SyncStateORM>(syncState)
    .filter(next => {
      if (!prevState) {
        prevState = next
        return false
      }

      return true
    })
    .map(next => {
      tempState = prevState
      prevState = next

      return {
        prev: tempState,
        next,
      }
    })

  return obs.subscribe({
    next: contentSyncAnalyticsHandler,
  })
}

/**
 * Headless monitor on Redux's contentSync slice to fire analytic events as side-effects
 * Follows the same approach as we do in other State machine analytics
 * @returns void
 */
const ContentSyncMonitor = () => {
  const subscription = useLazyRef(contentSyncObserver)

  useEffect(
    () => () => {
      subscription.current?.unsubscribe()
    },
    [],
  )

  return null
}

export default ContentSyncMonitor
