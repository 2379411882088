import { State as StateDef, ResolveTypegenMeta, BaseActionObject } from 'xstate';
import type * as Analytics from './analytics.types';
import { Typegen0 } from './analytics.machine.typegen';

// [NOTE] - Normally we can use some util helpers, other TS access to get this
//          but it's possible to run into a circular dependency error when using selector as machine guards
//        - This probably creates a 2nd instance of the State type but it's needed for now
type State = StateDef<
  Analytics.SMContext,
  Analytics.SMEvents,
  any,
  { value: any, context: Analytics.SMContext },
  ResolveTypegenMeta<
    Typegen0,
    Analytics.SMEvents,
    BaseActionObject,
    Analytics.SMServices
  >
>

// TAGS
export const isMachineReady = (state: State) => ({ isMachineReady: state.tags.has('MACHINE_READY') })

// MACHINE
export const isIdleState = (state: State) => ({ isIdleState: state.matches('idle') })

export const isTheNextLambdaCallScheduled = (state: State) =>
  ({ isTheNextLambdaCallScheduled: state.matches('ready.processing.fetchAthenaRecord.scheduled') })

export const isWaitingToRetryLambdaCall = (state: State) =>
  ({ isWaitingToRetryLambdaCall: state.matches('ready.processing.fetchAthenaRecord.retryAfterDelay') })

// CONTEXT
export const recentlyAccessedFolders = (state: State) => {
  return { recentlyAccessedFolders: state.context.analyticsData.personal.recentlyAccessedFolders }
}

export const recentlyAccessedDocuments = (state: State) => {
  return { recentlyAccessedDocuments: state.context.analyticsData.personal.recentlyAccessedDocuments }
}

export const mostAccessedDocuments = (state: State) => {
  return {
    mostAccessedDocuments: {
      personal: state.context.analyticsData.personal.mostAccessedDocuments,
      tenant: state.context.analyticsData.tenant.mostAccessedDocuments,
    },
  };
}
