import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useInterpret } from '@xstate/react';
import analyticsMachine from 'src/state/machines/analytics/analytics.machine';
import { AnalyticsData, AnalyticsService } from 'src/state/machines/analytics/analytics.types';
import useLazyRef from 'src/hooks/useLazyRef';
import useMachineSelector, { composite } from 'src/hooks/useSelector';
import * as analyticsSelector from 'src/state/machines/analytics/analytics.selector';
import store from 'src/state/redux/store';
import { useStateMachineLogger } from '../util';
import logger from 'src/utils/logger';

export interface AnalyticsContextType extends AnalyticsData {
  service: AnalyticsService,
  hasTheInitialLambdaCallFinished?: boolean,
}

export interface ProviderProps {
  children: ReactNode
  isLoggingOut: boolean
}

export const AnalyticsContext = createContext<AnalyticsContextType>(null!);
AnalyticsContext.displayName = 'AnalyticsContext';

const AnalyticsStateProvider: React.FC<PropsWithChildren<ProviderProps>> = ({
  children,
  isLoggingOut,
}) => {
  const userSettings = useMemo(() => store.getState().userSettings.records, []);
  const hasTheInitialLambdaCallFinished = useRef<boolean>(false);
  /* ANALYTICS STATE MACHINE START */
  const analyticsMachineInstance = useLazyRef(() => analyticsMachine.withContext({
    localAnalytics: [],
    analyticsToBatchStore: [],
    userSettings: userSettings[0],
    analyticsData: {
      personal: {
        mostAccessedDocuments: [],
        recentlyAccessedDocuments: [],
        recentlyAccessedFolders: [],
      },
      tenant: {
        mostAccessedDocuments: [],
      },
    },
  }))

  const service = useInterpret(
    analyticsMachineInstance.current!,
  )

  useStateMachineLogger(service, logger.analytics)

  const cond = useMachineSelector(
    service,
    (state) => composite(
      state,
      analyticsSelector.recentlyAccessedFolders,
      analyticsSelector.recentlyAccessedDocuments,
      analyticsSelector.mostAccessedDocuments,
      analyticsSelector.isTheNextLambdaCallScheduled,
      analyticsSelector.isWaitingToRetryLambdaCall,
    ),
  )

  useEffect(() => {
    if (isLoggingOut) {
      service.send({ type: 'USER_SIGNING_OUT' });
    }
  }, [isLoggingOut]);

  hasTheInitialLambdaCallFinished.current = useMemo(() =>
    hasTheInitialLambdaCallFinished.current || cond.isTheNextLambdaCallScheduled || cond.isWaitingToRetryLambdaCall
  , [cond.isTheNextLambdaCallScheduled, cond.isWaitingToRetryLambdaCall]);

  /* LOGGING STATE MACHINE END */

  const context: AnalyticsContextType = {
    service,
    hasTheInitialLambdaCallFinished: hasTheInitialLambdaCallFinished.current,
    personal: {
      recentlyAccessedFolders: cond.recentlyAccessedFolders,
      recentlyAccessedDocuments: cond.recentlyAccessedDocuments,
      mostAccessedDocuments: cond.mostAccessedDocuments.personal,
    },
    tenant: {
      mostAccessedDocuments: cond.mostAccessedDocuments.tenant,
    },
  };

  return (
    <AnalyticsContext.Provider value={context}>
      {children}
    </AnalyticsContext.Provider>
  );
};

AnalyticsStateProvider.displayName = 'AnalyticsStateProvider';

export const useAnalyticsState = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsState must be used within the AnalyticsStateProvider');
  }
  return context;
};

export default AnalyticsStateProvider;
