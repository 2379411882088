import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import im, { Draft } from 'immer'
import {
  DNABox,
  DNAButton,
  DNAChip,
  DNAChipStatus,
  DNADivider,
  DNAText,
  Iffy,
  luxColors,
} from '@alucio/lux-ui'

import { DocumentORM } from 'src/types/types'
import {
  defaultTenantFieldDocumentFilter,
  FilterEntry,
  filters,
  merge,
  modalFilterSystemFieldsQueries,
} from 'src/state/redux/document/query'

import { useCurrentUserORM, useUserTenant } from 'src/state/redux/selector/user'
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  CustomValues,
  DocumentStatus,
  FileType,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models'
import { FilterAndSortOptions } from 'src/state/redux/selector/document'
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { useLocation } from 'src/router'
import ROUTES from 'src/router/routeDef'

import capitalize from 'lodash/capitalize'
import useIsDebugView from 'src/hooks/useIsDebugView/useIsDebugView'

export type SetFilterStateFn = React.Dispatch<React.SetStateAction<FilterEntry[]>>

export interface DNADocumentFilterContextValue {
  activeFilters: FilterEntry[],
  setActiveFilters: SetFilterStateFn,
  filterEntries: FilterEntry[],
  filterSelectorOpts: FilterAndSortOptions<DocumentORM>,
  toggleFilter: (filterName: string, filterValue: string, c?: SetFilterStateFn) => void,
  resetToDefaultFilters: (c?: SetFilterStateFn) => void,
  clearFilters: (c?: SetFilterStateFn) => void,
  unpublishedToggle: boolean,
  toggleUnpublished: () => void,
}

interface DocumentFilterChipsProps {
  chipsStatus?: DNAChipStatus,
  documents: DocumentORM[];
  onClearFilters?: () => void;
  isLoading?: boolean
}

export interface FilterMap {
  [fieldId: string]: {
    fieldName: string,
    values: {
      [valueId: string]: {
        fieldName: string,
        fieldId: string,
        valueId: string,
        fieldValue: string,
        active: boolean,
      }
    }
  }
}

export const DNADocumentFilterContext = createContext<DNADocumentFilterContextValue>({
  activeFilters: [],
  filterEntries: [],
  filterSelectorOpts: {},
  setActiveFilters: (p) => p,
  toggleFilter: () => { },
  resetToDefaultFilters: () => { },
  clearFilters: () => { },
  unpublishedToggle: false,
  toggleUnpublished: () => { },
})
DNADocumentFilterContext.displayName = 'DNADocumentFilterContext'

const NON_TENANT_SPECIFIC_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldId: 'Associated Files',
    fieldName: 'Associated Files',
    fieldValue: 'Has associated files',
    valueId: 'Has associated files',
    default: false,
    active: false,
  },
];
const STATUS_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldId: 'Status',
    fieldName: 'Status',
    fieldValue: 'Revoked',
    valueId: 'Revoked',
    default: false,
    active: false,
  },
  {
    fieldId: 'Status',
    fieldName: 'Status',
    fieldValue: 'Published',
    valueId: 'Published',
    default: true,
    active: true,
  },
  {
    fieldId: 'Status',
    fieldName: 'Status',
    fieldValue: 'Not Published',
    valueId: 'Not Published',
    default: false,
    active: false,
  },
  {
    fieldId: 'Status',
    fieldName: 'Status',
    fieldValue: 'Archived',
    valueId: 'Archived',
    default: false,
    active: false,
  },
];
const FORMAT_TYPE_FILTER_ENTRIES: FilterEntry[] = [
  {
    fieldId: 'Type',
    fieldName: 'Type',
    fieldValue: FileType.PPTX,
    valueId: FileType.PPTX,
    default: false,
    active: false,
  },
  {
    fieldId: 'Type',
    fieldName: 'Type',
    fieldValue: FileType.PDF,
    valueId: FileType.PDF,
    default: false,
    active: false,
  },
  {
    fieldId: 'Type',
    fieldName: 'Type',
    fieldValue: FileType.WEB,
    valueId: FileType.WEB,
    default: false,
    active: false,
  },
  {
    fieldId: 'Type',
    fieldName: 'Type',
    fieldValue: 'VIDEO',
    valueId: 'VIDEO',
    default: false,
    active: false,
  },
  {
    fieldId: 'Type',
    fieldName: 'Type',
    fieldValue: FileType.HTML,
    valueId: FileType.HTML,
    default: false,
    active: false,
  },
];

export const useDNADocumentFilters = () => useContext(DNADocumentFilterContext)

// [TODO]: Could be a selector (or meta property)
// But still deciding if needs to be used in more than one place
// This is used to easily get info for the Filter Modal
export function mapFieldFilterEntries(
  customFields: CustomFieldDefinition[],
  defaultFilters?: CustomValues[],
  userTenant?: Tenant,
  isPublisher?: boolean,
  skipStatus: boolean = false,
) {
  // Map of the default filters
  const defaultFieldsFilter: { [key: string]: { [key: string]: boolean } } = {}
  for (const field of defaultFilters || []) {
    defaultFieldsFilter[field.fieldId] = {}
    for (const fieldValue of field?.values ?? []) {
      defaultFieldsFilter[field.fieldId][fieldValue] = true
    }
  }

  const filterEntries: FilterEntry[] = []
  // Include non-tenant specifc fields
  Object.values(FORMAT_TYPE_FILTER_ENTRIES).forEach((filterEntry: FilterEntry) => {
    filterEntries.unshift(filterEntry);
  });

  userTenant?.integrations?.forEach((integration: string | null) => {
    filterEntries.unshift({
      fieldId: 'Content Source',
      fieldName: 'Content Source',
      active: false,
      valueId: capitalize(integration!),
      fieldValue: capitalize(integration!),
      default: false,
    });
  });

  if (isPublisher && skipStatus === false) {
    Object.values(STATUS_FILTER_ENTRIES).forEach((filterEntry: FilterEntry) => {
      filterEntries.unshift(filterEntry);
    });
  }

  NON_TENANT_SPECIFIC_FILTER_ENTRIES.forEach((customFilterEntry: FilterEntry) => {
    filterEntries.unshift(customFilterEntry);
  });

  // Flatten the tenant fields + add additional default/active props
  for (const field of customFields ?? []) {
    for (const fieldValue of field.fieldValueDefinitions) {
      filterEntries.push({
        fieldId: field.id,
        fieldName: field.fieldLabel,
        fieldValue: fieldValue.label ?? fieldValue.value,
        valueId: fieldValue.id,
        default: defaultFieldsFilter[field.id]?.[fieldValue.id],
        active: defaultFieldsFilter[field.id]?.[fieldValue.id],
        isCustomLabel: true,
      })
    }
  }

  return filterEntries
}

export const DNADocumentFiltersProvider: React.FC<PropsWithChildren<{
  initUserDefaultFilters?: boolean}
>> = (props) => {
  const {
    children,
    initUserDefaultFilters = true,
  } = props
  const currentUser = useCurrentUserORM()
  const userTenant = useUserTenant()

  // TODO: we need to figure out a better way to validate if the current view is the publisher view
  const { pathname } = useLocation();
  const lowerCasedPathName = pathname.toLowerCase();
  const isPublisherSearch = lowerCasedPathName === ROUTES.SEARCH_RESULTS_PUBLISHER.PATH
  const isPublisher = [ROUTES.PUBLISHER_DASHBOARD.PATH].includes(lowerCasedPathName) || isPublisherSearch
  const isDebugRoute = useIsDebugView()
  const customFields = useTenantCustomFields({
    defaultSearchFilter: false,
    usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });

  const [unpublishedToggle, setUnpublishedToggle] = useState<boolean>(false)

  const filterEntries = useMemo(() => mapFieldFilterEntries(
    customFields,
    currentUser?.model?.defaultFiltersCustomValues,
    userTenant,
    isPublisher,
    unpublishedToggle,
  ), [currentUser, userTenant, unpublishedToggle])

  const [activeFilters, setActiveFilters] = useState<FilterEntry[]>(
    initUserDefaultFilters
      ? filterEntries.filter(entry => entry.default)
      : [],
  )

  // Curried functions allow us to pass in any setState fn that has a FilterEntry[] type
  //  This allows us to be able to reuse these functions in the filter modal
  //  where actual filters changes are not committed until the Apply button is pressed
  function toggleFilter(
    fieldId: string,
    valueId: string,
    curriedFn: SetFilterStateFn = setActiveFilters,
  ) {
    const curried = im((draft: Draft<FilterEntry[]>) => {
      const activeFilterIdx =
        draft.findIndex(entry => entry.valueId === valueId && entry.fieldId === fieldId)
      if (activeFilterIdx < 0) {
        const targetFilter =
          filterEntries.find(entry => entry.valueId === valueId && entry.fieldId === fieldId)
        if (targetFilter) { draft.push({ ...targetFilter, active: true }) }
      } else {
        draft.splice(activeFilterIdx, 1)
      }
    })

    curriedFn(curried)
  }

  function resetToDefaultFilters(curriedFn: SetFilterStateFn = setActiveFilters) {
    const curried = (p: FilterEntry[]): typeof p => {
      return filterEntries.filter(entry => entry.default)
    }
    curriedFn(curried)
  }

  function clearFilters(curriedFn: SetFilterStateFn = setActiveFilters) {
    curriedFn(() => [])
  }

  // [TODO-2126] - I believe filters are still calculating at the document level, not the version level
  //             - Also, how do we want to handle counting in offline?
  const docTenantFieldFilter = useMemo(() => {
    const requiredFilterFieldNames = Object.keys(
      activeFilters.reduce(
        (acc, activeFilter) => ({
          ...acc,
          [activeFilter.fieldId]: true,
        }),
        {},
      ))

    return {
      filter: {
        meta: {
          customValues: defaultTenantFieldDocumentFilter(requiredFilterFieldNames, activeFilters),
        },
      },
    }
  }, [activeFilters]);

  const docSystemFieldFilter = useMemo(() => {
    const activeFiltersMap = activeFilters.reduce<Record<string, string[]>>(
      (acc, activeFilter) => {
        if (!acc[activeFilter.fieldName])
        { acc[activeFilter.fieldName] = [activeFilter.fieldValue] }
        else
        { acc[activeFilter.fieldName].push(activeFilter.fieldValue) }

        return acc
      },
      { },
    )

    const activeSystemFieldFilters = Object
      .entries(modalFilterSystemFieldsQueries)
      .reduce<FilterAndSortOptions<DocumentORM>[]>(
        (acc, [systemFieldName, systemFieldQuery]) => {
          if (activeFiltersMap[systemFieldName])
          { acc.push(systemFieldQuery(activeFiltersMap[systemFieldName])) }
          return acc
        }
        , [],
      )

    const wrapperFnArr: FilterAndSortOptions<DocumentORM>[] = []
    activeSystemFieldFilters.forEach(filter => {
      if (filter.filter?.relations?.version) {
        wrapperFnArr.push(filter)
      }
    })

    const result = merge(...activeSystemFieldFilters)

    // We consolidate all filters that use version relations.version prop
    // Otherwise we will end up with only one filter for version prop after previous merge of activeSystemFieldFilters
    if (wrapperFnArr.length && result.filter?.relations) {
      result.filter.relations.version = (v) => {
        return wrapperFnArr.every((filter) => {
          if (typeof filter.filter?.relations?.version === 'function') {
            return filter.filter?.relations?.version(v)
          }

          throw Error('Expecting filter to be a function')
        })
      }
    }

    return result
  }, [activeFilters])

  const filterSelectorOpts: FilterAndSortOptions<DocumentORM> = useMemo(() => {
    const activeFilters = merge(
      docTenantFieldFilter,
      docSystemFieldFilter,
    )

    if (isPublisherSearch) {
      return activeFilters
    }

    return !unpublishedToggle
      // merge is also exported from the same query file as filters
      // we want to show active filters, the notPublished and has unpublished versions
      // the publisher is an special case because his 'published version includes the revoke, archive, and delete,
      ? (isPublisher || isDebugRoute)
        ? activeFilters
        // [NOTE] - Non publisher modes will ignore any active status filters (i.e. default active ones)
        // since merging will override any previous key/value
        : merge(activeFilters, filters.published)
      : merge(filters.hasUnpublishedVersion, activeFilters)
  }, [
    docTenantFieldFilter,
    docSystemFieldFilter,
    activeFilters,
    unpublishedToggle,
  ],
  );

  // [TODO]: Consider memoizing the functions?
  const value = {
    activeFilters,
    setActiveFilters,
    filterEntries,
    filterSelectorOpts,
    toggleFilter,
    resetToDefaultFilters,
    clearFilters,
    unpublishedToggle,
    toggleUnpublished: () => setUnpublishedToggle(p => !p),
  }

  return (
    <DNADocumentFilterContext.Provider value={value}>
      { children}
    </DNADocumentFilterContext.Provider>
  )
}

export const DNADocumentFiltersChips = React.memo((props: DocumentFilterChipsProps) => {
  const { documents, chipsStatus = 'secondary', isLoading } = props
  const {
    activeFilters,
    toggleFilter,
    resetToDefaultFilters,
    clearFilters,
    unpublishedToggle,
  } = useDNADocumentFilters()

  const handleClearFilter = () => {
    clearFilters()
    props.onClearFilters?.()
  }

  return (
    <DNABox
      alignY="center"
      style={{ minHeight: 36 }}
    >
      <DNABox fill childFill={1} alignY="center" alignX="start">
        <DNABox
          alignY="center"
          spacing="sm"
          // Vertical Divider doesn't work super well with Box's wrapping system
          childStyle={{ height: 24, justifyContent: 'center' }}
        >
          <Iffy is={!isLoading}>
            <DNAText testID="filter-count-label">
              {documents.length} item(s)
            </DNAText>
          </Iffy>
          <DNADivider vertical style={{ marginHorizontal: 4 }} />
          <DNAText>Filters:</DNAText>
        </DNABox>

        {/* Disable filter chips if "In Progress" mode */}
        <DNABox
          shrink
          wrap="start"
          alignY="start"
          spacing="xs"
          style={{ marginLeft: 8 }}
          childStyle={{ paddingVertical: 2 }}
        >
          {
              activeFilters.map(entry => (
                <DNAChip
                  testID="filter-chip"
                  rounded="full"
                  key={entry.fieldValue}
                  status={chipsStatus}
                  onClose={() => { toggleFilter(entry.fieldId, entry.valueId) }}
                >
                  { entry.fieldValue}
                </DNAChip>
              ))
            }
        </DNABox>
        <DNABox
          alignY="center"
          childStyle={{ height: 24, justifyContent: 'center' }}
        >
          <DNABox
            style={{ paddingHorizontal: 16 }}
            spacing="sm"
          >
            {!unpublishedToggle && <DNAButton
              testID="filter-chips-reset-button"
              status="primary"
              appearance="ghostLink"
              padding="none"
              onPress={() => resetToDefaultFilters()}
            >
              Set to default
            </DNAButton>}
            <Iffy is={activeFilters.length}>
              <DNADivider
                vertical
                style={{
                  marginVertical: 4,
                  backgroundColor: luxColors.primary.primary,
                }}
              />
              <DNAButton
                testID="filter-chips-clear-button"
                status="primary"
                appearance="ghostLink"
                padding="none"
                onPress={handleClearFilter}
              >
                Clear
              </DNAButton>
            </Iffy>
          </DNABox>
        </DNABox>
      </DNABox>
    </DNABox>
  )
});

// COUNTS, PER FIELDS, HOW MANY DOCUMENTS THERE ARE
export function countDocumentCustomValues(
  documents: DocumentORM[],
  isPublisher?: boolean,
  userTenant?: Tenant,
): DocumentCount {
  const documentCount: DocumentCount = {
    'Associated Files': {
      'Has associated files': 0,
    },
    'Content Source': {},
    Status: {
      'Revoked': 0,
      'Published': 0,
      'Archived': 0,
      'Not Published': 0,
    },
    Type: {},
  };

  for (const documentORM of documents) {
    const docVersionORM = documentORM.relations.version.latestUsableDocumentVersionORM

    // HAS ASSOCIATED FILES
    if (docVersionORM?.relations.associatedFiles.length) {
      documentCount['Associated Files']['Has associated files']++;
    }

    // STATUS
    if (isPublisher) {
      switch (documentORM.model.status) {
        case DocumentStatus.NOT_PUBLISHED:
          documentCount.Status['Not Published']++;
          break;
        case DocumentStatus.PUBLISHED:
          documentCount.Status.Published++;
          break;
        case DocumentStatus.ARCHIVED:
          documentCount.Status.Archived++;

          break;
        case DocumentStatus.REVOKED:
          documentCount.Status.Revoked++;
      }
    }

    // FILE TYPE
    if (!documentCount.Type[documentORM.model.type]) {
      documentCount.Type[documentORM.model.type] = 0;
    }

    documentCount.Type[documentORM.model.type]++;

    // CUSTOM TENANT FIELDS
    const { configsMap } = documentORM.meta.customValues;
    if (configsMap) {
      Object.values(configsMap).forEach((value) => {
        if (!documentCount[value.field.id]) {
          documentCount[value.field.id] = {};
        }

        value.valuesDefinition?.forEach((definition) => {
          if (!documentCount[value.field.id][definition.id]) {
            documentCount[value.field.id][definition.id] = 0;
          }
          documentCount[value.field.id][definition.id]++;
        })
      })
    }

    // CONTENT SOURCE
    if (userTenant?.integrations?.length && documentORM.relations.version.latestUsableDocumentVersionORM) {
      const docVer = documentORM.relations.version.latestUsableDocumentVersionORM.model
      if (docVer.integrationType) {
        if (!documentCount['Content Source'][capitalize(docVer.integrationType)]) {
          documentCount['Content Source'][capitalize(docVer.integrationType)] = 0;
        }

        documentCount['Content Source'][capitalize(docVer.integrationType)]++;
      }
    }
  }

  return documentCount;
}

// [TODO] - Correctly extract props from `Component` generic T for use in composition
export const withDNADocumentFilters = <T extends { initUserDefaultFilters?: boolean }>
  (Component) => ({ initUserDefaultFilters, ...restProps }: T) => {
    return (
      <DNADocumentFiltersProvider
        initUserDefaultFilters={initUserDefaultFilters}
      >
        <Component {...restProps as T} />
      </DNADocumentFiltersProvider>
    )
  }

export interface DocumentCount {
  [fieldId: string]: {
    [valueId: string]: number
  }
}

export default {
  withDNADocumentFilters,
  Provider: DNADocumentFiltersProvider,
  Chips: DNADocumentFiltersChips,
}
