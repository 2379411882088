import React, { useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DNABox, DNAButton, DNAContextMenu, DNADivider, DNASpinner, DNAText, Iffy } from '@alucio/lux-ui';
import { DocumentORM } from 'src/types/orms';
import { useAnalyticsState } from 'src/state/machines/analytics/analyticsProvider';
import DNAFlatList from 'src/components/DNA/FlatList/DNAFlatList';
import { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import { useDNADocumentActions } from 'src/components/DNA/Document/DNADocument.actions';
import WidgetContainer from '../WidgetContainer';
import { getAvailableVariant, useGetWidgetsDocuments } from './utils';

export enum DocumentWidgetVariant {
  MOST_VIEWED = 'Most Viewed Documents',
  TEAM_MOST_VIEWED = 'Team Most Viewed Documents',
  RECENTLY_VIEWED = 'Recently Viewed Documents',
  RECENTLY_UPDATED = 'Recently Updated Documents'
}

interface Props {
  variant: DocumentWidgetVariant
}

const style = StyleSheet.create({
  loadingWrapper: {
    height: 320,
  },
  options: {
    padding: 16,
  },
});

interface MostAccessedVariantProps {
  internalVariant: DocumentWidgetVariant,
  setInternalVariant: (variant: DocumentWidgetVariant) => void,
}

const DocumentsSetPopover = (props: MostAccessedVariantProps) => {
  const { internalVariant, setInternalVariant } = props;
  const availableVariant = useMemo(() => getAvailableVariant(internalVariant), [internalVariant]);

  const onOptionSelected = (): void => {
    setInternalVariant(availableVariant);
  };

  return (
    <DNAContextMenu placement="bottom end">
      <DNAContextMenu.Anchor>
        <DNAButton
          status="tertiary"
          appearance="outline"
          padding="xs"
          iconLeft="chevron-down"
        />
      </DNAContextMenu.Anchor>
      <DNAContextMenu.Items>
        <DNAContextMenu.Item
          title={availableVariant}
          onPress={onOptionSelected}
        />
      </DNAContextMenu.Items>
    </DNAContextMenu>
  );
};

const Documents: React.FC<Props> = ({ variant }) => {
  const documentActions = useDNADocumentActions();
  const [internalVariant, setInternalVariant] = useState<DocumentWidgetVariant>(variant);

  /* CONDITIONALS */
  const { hasTheInitialLambdaCallFinished } = useAnalyticsState();
  const isRecentlyAccessed = variant === DocumentWidgetVariant.RECENTLY_VIEWED;
  const [isViewMoreActive, setIsViewMoreActive] = useState<boolean>(false);
  const displayLoadingIndicator = !isRecentlyAccessed && !hasTheInitialLambdaCallFinished;
  /* END CONDITIONALS */

  const allDocuments = useGetWidgetsDocuments(internalVariant);
  const displayedItems = useMemo(() => (isViewMoreActive ? allDocuments.slice(0, 10) : allDocuments.slice(0, 5)), [
    isViewMoreActive,
    allDocuments,
  ]);

  const toggleViewMore = () => setIsViewMoreActive((prev) => !prev);

  const handlePressDocument = (documentORM: DocumentORM) => documentActions.preview(documentORM)();

  return (
    <WidgetContainer
      titleRightSideComponent={
        <DocumentsSetPopover
          internalVariant={internalVariant}
          setInternalVariant={setInternalVariant}
        />
      }
      title={internalVariant}
    >
      { /* LOADING INDICATOR */ }
      <Iffy is={displayLoadingIndicator}>
        <DNABox
          style={style.loadingWrapper}
          alignX="center"
          alignY="center"
          spacing="lg"
          appearance="col"
          fill
        >
          <DNASpinner />
          <DNAText bold status="flatAlt">Loading...</DNAText>
        </DNABox>
      </Iffy>
      { /* CONTENT */ }
      <Iffy is={!displayLoadingIndicator}>
        <DNABox appearance="col" fill>
          <DNAFlatList
            items={displayedItems}
            variant="dashboard"
            onPress={handlePressDocument}
            emptyVariant={EmptyVariant.DocumentDashboardEmpty}
          />
          <Iffy is={allDocuments.length > 5}>
            <DNABox appearance="col" spacing="md">
              <DNADivider />
              <DNAButton
                appearance="ghost"
                onPress={toggleViewMore}
                padding="xs"
                size="xs"
              >
                { isViewMoreActive ? 'View less' : 'View more' }
              </DNAButton>
            </DNABox>
          </Iffy>
        </DNABox>
      </Iffy>
    </WidgetContainer>
  );
};

export default Documents;
