import React from 'react';
import { Image, StyleSheet } from 'react-native';
import { Iffy, DNABox, DNADivider, DNAText, InformationMessage } from '@alucio/lux-ui';
import { useAppSettings } from 'src/state/context/AppSettings';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import { SyncStatusInfo } from 'src/components/DNA/Header/SyncStatusButton';
import SendLogs from '../SendLogs';
import ToggleOfflineButton, { ToggleOfflineButtonVariant } from './ToggleOfflineButton';
import BeaconAppInstallStepRow from './BeaconAppInstallStepRow';

const pwaInstall = require('../../../../assets/images/pwa_install.png');
const offlineSynced = require('../../../../assets/images/offline_synced.png');

const styles = StyleSheet.create({
  mainWrapper: {
    minWidth: 500,
    paddingTop: 40,
  },
  installStepContainer: {
    borderColor: colors['color-gray-80'],
    borderWidth: 1,
    borderRadius: 4,
  },
  imageBorder: {
    borderColor: colors['color-gray-100'],
    borderWidth: 1,
  },
  pwaInstallImage: {
    width: 260,
    height: 159,
  },
  offlineSyncedImage: {
    width: 260,
    height: 123,
  },
});

interface InstructionStep {
  stepNumber: number,
  text: string,
  rightElement?: React.ReactElement,
}

// eslint-disable-next-line max-len
const ACTIVATE_HEADER = 'To install the Beacon application on your laptop, please follow the steps below. Beacon’s laptop application provides users with access to all their content when Wi-Fi is unavailable.';

const ACTIVATE_INSTRUCTION: InstructionStep[] = [
  {
    stepNumber: 1,
    text: 'Ensure you are connected to the internet. Do not use a private browser.',
  },
  {
    stepNumber: 2,
    text: 'Click the “Activate” button to enable document sync for offline use. This screen will refresh.',
    rightElement: <ToggleOfflineButton variant={ToggleOfflineButtonVariant.Activate} />,
  },
  {
    stepNumber: 3,
    // eslint-disable-next-line max-len
    text: 'After offline mode is enabled (this only needs to be done once), an install icon will appear in the address bar.',
    rightElement: <Image source={pwaInstall} style={[styles.pwaInstallImage, styles.imageBorder]}/>,
  },
  {
    stepNumber: 4,
    // eslint-disable-next-line max-len
    text: 'Click the icon to install the Beacon application on your laptop. Once installed, you will be able to access all features and functionalities through the Beacon Application for Laptop.',
  },
  {
    stepNumber: 5,
    // eslint-disable-next-line max-len
    text: "Once your content is fully synced, you will see a green check mark, and you're all set for offline use through the Beacon application!",
    rightElement: <Image source={offlineSynced} style={[styles.offlineSyncedImage, styles.imageBorder]}/>,
  },
];

const DEACTIVATE_HEADER = 'To deactivate offline use, follow the steps below.';

const DEACTIVATE_INSTRUCTION: InstructionStep[] = [
  {
    stepNumber: 1,
    text: 'Ensure you are connected to the internet. Do not use a private browser.',
  },
  {
    stepNumber: 2,
    text: 'Click the “Deactivate” button to deactivate document sync for offline use.',
    rightElement: <ToggleOfflineButton variant={ToggleOfflineButtonVariant.Deactivate} />,
  },
];

const Offline = () => {
  const { isOfflineEnabled } = useAppSettings()

  return (
    <DNABox fill appearance="col" spacing="lg" alignY="center" style={styles.mainWrapper}>
      {/* ENHANCED LOGGING BUTTON */}
      <DNABox fill alignY="center" spacing="sm">
        <SendLogs />
      </DNABox>
      <DNADivider height={2}/>
      <Iffy is={isOfflineEnabled}>
        <InformationMessage
          text="Document sync has been successfully activated."
          variance="success"
          customWrapperStyle={{ margin: 0 }}
        />
      </Iffy>
      {/* STEPS TO ACTIVATE */}
      <DNAText h5>{ACTIVATE_HEADER}</DNAText>
      <DNABox appearance="col" style={styles.installStepContainer}>
        {ACTIVATE_INSTRUCTION.map((step, index) => {
          const { stepNumber, text, rightElement } = step;
          const isLastItem = ACTIVATE_INSTRUCTION.length - 1 === index;
          return (
            <BeaconAppInstallStepRow
              stepNum={stepNumber}
              text={text}
              rightElement={rightElement}
              isLastItem={isLastItem}
            />
          )
        })}
      </DNABox>
      {/* SYNC STATUS INFO */}
      <Iffy is={isOfflineEnabled}>
        <DNABox alignX="start" fill>
          <DNABox style={{ maxWidth: 400 }}>
            <SyncStatusInfo />
          </DNABox>
        </DNABox>
      </Iffy>
      {/* STEPS OF UNINSTALL BEACON APP */}
      <Iffy is={isOfflineEnabled}>
        <DNAText h5>{DEACTIVATE_HEADER}</DNAText>
        <DNABox appearance="col" style={styles.installStepContainer}>
          {DEACTIVATE_INSTRUCTION.map((step, index) => {
            const { stepNumber, text, rightElement } = step;
            const isLastItem = DEACTIVATE_INSTRUCTION.length - 1 === index;
            return (
              <BeaconAppInstallStepRow
                stepNum={stepNumber}
                text={text}
                rightElement={rightElement}
                isLastItem={isLastItem}
              />
            )
          })}
        </DNABox>
      </Iffy>
    </DNABox>
  );
};

export default Offline;
